import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'Shib Swap Finance - The best AMM DEX on Shib Swap Finance (CORE)',
  description:
    'The most popular AMM on Shib Swap Finance by user count! Earn PIPILOL through yield farming or win it in the Lottery, then stake it in Syrup Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by Pipiswap Finance), NFTs, and more, on a platform you can trust.',
  image: 'https://bakihanma.net/images/swapimg/twitter.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  switch (path) {
    case '/':
      return {
        title: `${t('Home')} | ${t('Pipiswap Finance')}`,
      }
    case '/competition':
      return {
        title: `${t('Trading Battle')} | ${t('Pipiswap Finance')}`,
      }
    case '/prediction':
      return {
        title: `${t('Prediction')} | ${t('Pipiswap Finance')}`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | ${t('Pipiswap Finance')}`,
      }
    case '/pools':
      return {
        title: `${t('Pools')} | ${t('Pipiswap Finance')}`,
      }
    case '/lottery':
      return {
        title: `${t('Lottery')} | ${t('Pipiswap Finance')}`,
      }
    case '/collectibles':
      return {
        title: `${t('Collectibles')} | ${t('Pipiswap Finance')}`,
      }
    case '/ifo':
      return {
        title: `${t('Initial Farm Offering')} | ${t('Pipiswap Finance')}`,
      }
    case '/teams':
      return {
        title: `${t('Leaderboard')} | ${t('Pipiswap Finance')}`,
      }
    case '/profile/tasks':
      return {
        title: `${t('Task Center')} | ${t('Pipiswap Finance')}`,
      }
    case '/profile':
      return {
        title: `${t('Your Profile')} | ${t('Pipiswap Finance')}`,
      }
    default:
      return null
  }
}
