import { MenuEntry } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'
import { PATH_SWAP } from 'views/utils'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
 
  
	{
		label: t('Core'),
		icon: 'Link2Icon',
		 
		
		
		items: [
		   
			
		],},
  
  {
    label: t('Trade'),
    icon: 'TradeIcon',
    items: [
      {
        label: t('Swap'),
        href: `${PATH_SWAP}?outputCurrency=0x49cc83dc4cf5d3ecdb0b6dd9657c951c52ec7dfa`,
      },
      {
        label: t('Liquidity'),
        href: '/pool',

      },
    ],
  },
  
 
  
	{
	label: t(''),
	icon: 'PoolIcon',
	href: '',
	},
				
    {
	label: t(''),
	icon: 'InfoIcon',
	href: '',
	}, 
		
		
		
		
		
		
		
		
		
		
  
  
 
        



]

export default config
