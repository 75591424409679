import { ChainId, Token } from '@pancakeswap/sdk'

export const CAKE: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    18,
    'CAKE',
    'PancakeSwap Token',
  ),
  [ChainId.TESTNET]: new Token(
    ChainId.TESTNET,
    '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
    18,
    'CAKE',
    'PancakeSwap Token',
  ),
}
export const BUSD: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0x900101d06a7426441ae63e9ab3b9b0f63be145f1',
    6,
    'USDT',
    'USDT',
  ),
  [ChainId.TESTNET]: new Token(
    ChainId.TESTNET,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    18,
    'BUSD',
    'Binance USD',
  ),
}
export const WBNB = new Token(ChainId.MAINNET, '0x40375c92d9faf44d2f9db9bd9ba41a3317a2404f', 18, 'CORE', 'Wrapped wCORE')
export const DAI = new Token(ChainId.MAINNET, '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3', 18, 'DAI', 'Dai Stablecoin')
export const USDT = new Token(ChainId.MAINNET, '0x55d398326f99059fF775485246999027B3197955', 18, 'USDT', 'Tether USD')
export const BTCB = new Token(ChainId.MAINNET, '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c', 18, 'BTCB', 'Binance BTC')
export const UST = new Token(
  ChainId.MAINNET,
  '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
  18,
  'UST',
  'Wrapped UST Token',
)
export const ETH = new Token(
  ChainId.MAINNET,
  '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  18,
  'ETH',
  'Binance-Peg Ethereum Token',
)
export const USDC = new Token(
  ChainId.MAINNET,
  '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  18,
  'USDC',
  'Binance-Peg USD Coin',
)

const tokens = {
		core: {
		symbol: 'CORE',
		projectLink: 'http://swap.falcox.net/',
		},
		
		wcore: {
		symbol: 'WCORE',
		address: {
		1116: '0x40375c92d9faf44d2f9db9bd9ba41a3317a2404f',
		5438: '0x40375c92d9faf44d2f9db9bd9ba41a3317a2404f',
		},
		decimals: 18,
		projectLink: 'http://swap.falcox.net/',
		},
		
		
		
	    pipilol: {
		symbol: 'PIPI',
		address: {
		1116: '0x49cc83dc4cf5d3ecdb0b6dd9657c951c52ec7dfa',
		5438: '0x49cc83dc4cf5d3ecdb0b6dd9657c951c52ec7dfa',
		},
		decimals: 18,
		projectLink: 'http://swap.falcox.net/',
		},
		
		hice: {
		symbol: 'HICE',
		address: {
		1116: '0xecf2addc7267d65fba77a8cfb23a9160b435fa31',
		5438: '0xecf2addc7267d65fba77a8cfb23a9160b435fa31',
		},
		decimals: 18,
		projectLink: 'https://hicetokenlab.com/',
		},
		
		kombat: {
		symbol: 'KOMBAT',
		address: {
		1116: '0xa6db49298e31d1e57e2a30ffcc2d288fce1f738e',
		5438: '0xa6db49298e31d1e57e2a30ffcc2d288fce1f738e',
		},
		decimals: 18,
		projectLink: 'https://sites.google.com/view/pipikombat/',
		},
		
		coco: {
		symbol: 'COCO',
		address: {
		1116: '0x909c72b702ae7cf14539a69a7f089b8796f912f1',
		5438: '0x909c72b702ae7cf14539a69a7f089b8796f912f1',
		},
		decimals: 18,
		projectLink: 'http://cocokombat.xyz/',
		},
		
		pepe: {
		symbol: 'PEPE',
		address: {
		1116: '0x9324ee711bd9e6c05382d14bbca36b24bf8ae8a5',
		5438: '0x9324ee711bd9e6c05382d14bbca36b24bf8ae8a5',
		},
		decimals: 18,
		projectLink: 'http://cocokombat.xyz/',
		},
	
		
		shib: {
		symbol: 'SHIB',
		address: {
		1116: '0x3eb3770bed92cdb09f3ef44058b217c6fd0e8160',
		5438: '0x3eb3770bed92cdb09f3ef44058b217c6fd0e8160',
		},
		decimals: 18,
		projectLink: 'https://shibatoken.com/',
		},
		
		floki: {
		symbol: 'FLOKI',
		address: {
		1116: '0x7707932e001e0209a3e064a6e0f66795f9149208',
		5438: '0x7707932e001e0209a3e064a6e0f66795f9149208',
		},
		decimals: 18,
		projectLink: 'https://floki.com/',
		},
		
		
		
 // ***************************************** client token   ********************************** 		
		
		 
		
		

        	
		
		
		

   
 // ***************************************** default token   ********************************** 
 
		cake: {
		symbol: 'CAKE',
		address: {
		1116: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
		5438: '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
		},
		decimals: 18,
		projectLink: 'https://pancakeswap.finance/',
		},
 
		wbnb: {
		symbol: 'wCORE',
		address: {
		1116: '0x40375c92d9faf44d2f9db9bd9ba41a3317a2404f',
		5438: '0x40375c92d9faf44d2f9db9bd9ba41a3317a2404f',
		},
		decimals: 18,
		projectLink: 'http://swap.falcox.net/',
		},
		
		
		busd: {
		symbol: 'USDT',
		address: {
		1116: '0x900101d06a7426441ae63e9ab3b9b0f63be145f1',
		5438: '',
		},
		decimals: 6,
		projectLink: 'https://www.paxos.com/busd/',
		},

		syrup: {
		symbol: 'SYRUP',
		address: {
		1116: '0xa485fc9f634c91568b48a25d24c4a0dc34714f71',
		5438: '0xa485fc9f634c91568b48a25d24c4a0dc34714f71',
		},
		decimals: 18,
		projectLink: 'https://pancakeswap.finance/',
		},

		bondly: {
		symbol: 'BONDLY',
		address: {
		1116: '0x96058f8C3e16576D9BD68766f3836d9A33158f89',
		5438: '',
		},
		decimals: 18,
		projectLink: 'https://www.bondly.finance/',
		},

		safemoon: {
		symbol: 'SAFEMOON',
		address: {
		1116: '0x8076C74C5e3F5852037F31Ff0093Eeb8c8ADd8D3',
		5438: '',
		},
		decimals: 9,
		projectLink: 'https://safemoon.net/',
		},
  
}


export default tokens
